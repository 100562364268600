import moment from "moment";
import { ICloud, IReplicaSet, IVertical } from "../containers/HomePage/reducer";

export enum EVerticalStatus {
    Ready,
    Initializing,
    Updating,
    Deleting,
    Failed,
}

export class Status {
    public static Normalize(status: string): EVerticalStatus {
        switch (status) {
            case "CREATE_COMPLETE":
            case "UPDATE_COMPLETE":
            case "Succeeded":
                return EVerticalStatus.Ready;
            case "CREATE_IN_PROGRESS":
            case "Running":
                return EVerticalStatus.Initializing;
            case "UPDATE_IN_PROGRESS":
            case "UPDATE_COMPLETE_CLEANUP_IN_PROGRESS":
                return EVerticalStatus.Updating;
            case "DELETE_IN_PROGRESS":
            case "Canceling":
                return EVerticalStatus.Deleting;
            case "UPDATE_ROLLBACK_COMPLETE":
            case "Failed":
            case "Canceled":
            default:
                return EVerticalStatus.Failed;
        }
    }

    public static ToName(status: string): string {
        switch (Status.Normalize(status)) {
            case EVerticalStatus.Ready:
                return "Ready";
            case EVerticalStatus.Initializing:
                return "Initializing";
            case EVerticalStatus.Updating:
                return "Updating";
            case EVerticalStatus.Deleting:
                return "Deleting";
            case EVerticalStatus.Failed:
                return "Failed";
        }
    }
}

export class VerticalToolsJenkins {
    public static JobStartFormUrl(jobName: string, jobParams: Array<[string, string]>): string {
        return "https://pf-jenkins.playfab.com/view/Verticals/job/"
            + encodeURIComponent(jobName)
            + "/parambuild/?"
            + jobParams.map(
                (value: string[]) => encodeURIComponent(value[0]) + "=" + encodeURIComponent(value[1])).join("&");
    }

    public static SdkJobUrl(vertical: string): string {
        return `https://pf-jenkins.playfab.com/view/SDK/job/SDK_BuildAll/parambuild/?delay=0sec&VerticalName=${vertical}&GitDestBranch=doNotCommit&apiSpecSource=-apiSpecPfUrl`;
    }
}

export class VerticalToolsUrl {
    public static isProductionVertical(cloudOrStamp: string, vertical: string) {
        return vertical === "master" ||
            vertical.startsWith("p-") && this.isProductionCloud(cloudOrStamp) ||
            vertical === "low" ||
            vertical === "main" ||
            cloudOrStamp === "china" ||
            cloudOrStamp === "rblx" ||
            cloudOrStamp === "upr-usj" ||
            cloudOrStamp === "upr-ush";
    }

    public static applicationRoute(vertical: string, application: string, cloud: string): string {
        const cloudPart = cloud === "main" ? "" : "." + cloud;
        switch (application) {
            case "mainserver":
                return `https://${vertical}${cloudPart}.playfabapi.com/healthstatus`;
            case "mainserver_stack":
                return `https://${vertical}.api.${VerticalToolsUrl.getStampName(cloud)}.${VerticalToolsUrl.getStackPartitionUrl(cloud)}/healthstatus`;
            case "logicserver":
                return `https://${vertical}${cloudPart}.playfablogic.com/healthstatus`;
            case "logicserver_stack":
                return `https://${vertical}.logicserver.${VerticalToolsUrl.getStampName(cloud)}.${VerticalToolsUrl.getStackPartitionUrl(cloud)}/healthstatus`;
            case "cloudscriptint_stack":
                return `https://${vertical}.csint.${VerticalToolsUrl.getStampName(cloud)}.${VerticalToolsUrl.getStackPartitionUrl(cloud)}/healthstatus`;
            case "insightsproxy_stack":
                return `https://insightsproxy.${VerticalToolsUrl.getStampName(cloud)}.${VerticalToolsUrl.getStackPartitionUrl(cloud)}/healthstatus`;
            case "legacymatchmaker_stack":
                return `https://legacymatch.${VerticalToolsUrl.getStampName(cloud)}.${VerticalToolsUrl.getStackPartitionUrl(cloud)}/healthstatus`;
            case "gamemanager":
                return `https://${vertical}${cloudPart}.playfab.com`;
            case "gamemanager_stack":
                return `https://portal.${VerticalToolsUrl.getStampName(cloud)}.${VerticalToolsUrl.getStackPartitionUrl(cloud)}`;
            case "insightsproxy_stack":
                return `https://insightsproxy.${VerticalToolsUrl.getStampName(cloud)}.${VerticalToolsUrl.getStackPartitionUrl(cloud)}/healthstatus`;
            case "matchmakerprimary":
                return `http://${vertical}${cloudPart}-matchmaker-primary.playfabapi.com/healthstatus`;
            case "matchmakersecondary":
                return `http://${vertical}-matchmaker-secondary.playfabapi.com/healthstatus`;
            case "playerportal":
                return `http://${vertical}-player.playfab.com/healthstatus`;
            case "playerportal_stack":
                return `https://player.${VerticalToolsUrl.getStampName(cloud)}.${VerticalToolsUrl.getStackPartitionUrl(cloud)}/healthstatus`;
            default:
                return "";
        }
    }

    public static applicationCommitRoute(vertical: string, cloud: string, application: string, commit: string): string {
        const commitPart = commit;
        const cloudPart = cloud === "main" ? "" : "." + cloud;

        switch (application) {
            case "mainserver":
                return `https://${vertical}-${commitPart}${cloudPart}.playfabapi.com/healthstatus`;
            case "mainserverleaderboard":
                return `https://${vertical}-${commitPart}-leaderboard${cloudPart}.playfabapi.com/healthstatus`;
            case "mainserverplaystreamint":
                return `https://${vertical}-${commitPart}-playstream-int${cloudPart}.playfabapi.com/healthstatus`;
            case "mainservermatchproxy":
                return `https://${vertical}-${commitPart}-matchproxy${cloudPart}.playfabapi.com/healthstatus`;
            case "logicserver":
                return `https://${vertical}-${commitPart}${cloudPart}.playfablogic.com/healthstatus`;
            case "gamemanager":
                return `https://${vertical}-${commitPart}${cloudPart}.playfab.com`;
            case "matchmakerprimary":
                return `https://${vertical}-${commitPart}-matchmaker-primary.playfabapi.com/healthstatus`;
            case "matchmakersecondary":
                return `https://${vertical}-${commitPart}-matchmaker-secondary.playfabapi.com/healthstatus`;
            case "playerportal":
                return `https://${vertical}-${commitPart}-player.playfab.com/healthstatus`;
            default:
                return "";
        }
    }

    public static managedClusterRoute(subscription: string, stamp: string, cluster: string): string {
        return `https://ms.portal.azure.com/?feature.msaljs=true#@microsoft.onmicrosoft.com/resource/subscriptions/${subscription}/resourceGroups/${stamp}-01-shared/providers/Microsoft.ContainerService/managedClusters/${cluster}/overview`
    }

    public static trafficManagerRoute(subscription: string, stamp: string, cluster: string): string {
        const clusterNameParts = cluster.split("-");
        const clusterType = clusterNameParts[clusterNameParts.length - 3];
        const tm = `${stamp}-pftm-${clusterType}`;
        return `https://ms.portal.azure.com/?feature.msaljs=true#@microsoft.onmicrosoft.com/resource/subscriptions/${subscription}/resourceGroups/${stamp}-stamp/providers/Microsoft.Network/trafficmanagerprofiles/${tm}/overview`
    }

    public static replicaSetRoute(subscription: string, stamp: string, namespace: string, cluster: string, replicaSetName: string, application: string): string {
        return `https://ms.portal.azure.com/#view/Microsoft_Azure_ContainerService/AksK8ResourceMenuBlade/~/overview-ReplicaSet/aksClusterId/%2Fsubscriptions%2F${subscription}%2FresourceGroups%2F${stamp}-01-shared%2Fproviders%2FMicrosoft.ContainerService%2FmanagedClusters%2F${cluster}/resource~/%7B"kind"%3A"ReplicaSet"%2C"metadata"%3A%7B"name"%3A"${replicaSetName}"%2C"namespace"%3A"${namespace}"%7D%7D`;
    }

    private static getPfApplicationName(application: string) {
        switch (application) {
            case "mainserver_azure":
            case "mainserver_stack":
                return "mainserver";
            case "queueprocessor_stack":
                return "queueprocessor";
            case "taskprocessor_stack":
                return "taskprocessor";
            case "legacymatchmaker_stack":
                return "legacymatchmaker";
            case "cloudscriptint_stack":
                return "cloudscriptint";
            case "insightsproxy_stack":
                return "insightsproxy";
            case "playerportal_stack":
                return "playerportal";
            case "gamemanager_stack":
                return "gamemanager";
            case "insightsproxy_stack":
                return "insightsproxy";
            case "logicserver_stack":
                return "logicserver";
            case "traefik_stack":
                return "traefik";
            case "playstream_headdispatcher_stack":
                return "headdispatcher"
            case "playstream_actionprocessor_stack":
                return "actionprocessor"
            case "playstream_analyticsprocessor_stack":
                return "analyticsprocessor"
            case "playstream_entityprofileeventprocessor_stack":
                return "entityprofileeventprocessor"
            case "playstream_entityactionprocessor_stack":
                return "entityactionprocessor"
            case "playstream_indexprocessor_stack":
                return "indexprocessor"
            case "playstream_segmentprocessor_stack":
                return "segmentprocessor"
            case "playstream_statsprocessor_stack":
                return "statsprocessor"
            case "playstream_webhooksprocessor_stack":
                return "webhooksprocessor"
            case "playstream_eventarchiver_stack":
                return "eventarchiver"
            case "playstream_livesite_stack":
                return "livesite"
            case "playstream_playstreammonitor_stack":
                return "playstreammonitor"
            default:
                return application;
        }
    }

    public static mdmDashForApplicationCluster(deploymentName: string, namespace: string, cloud: string, cluster: string): string {
        const overridePrefix: string = "?overrides=[";
        const overrideSuffix: string = "]";
        const stackOverrides = `${overridePrefix}${VerticalToolsUrl.getOverrideString("namespace", namespace)},${VerticalToolsUrl.getOverrideString("container_name", deploymentName)},${VerticalToolsUrl.getOverrideString("cluster", cluster)}${overrideSuffix}`;

        return VerticalToolsUrl.getDashboardUrl("k8s/k8s_pod", cloud, stackOverrides);
    }

    public static mdmDashForApplicationCommit(vertical: string, application: string, commit: string, cloud: string): string {
        const overridePrefix: string = "?overrides=[";
        const overrideSuffix: string = "]";

        // General overrides
        const azureCloudOverride: string = VerticalToolsUrl.getOverrideString("cloud", cloud + "_azure");
        const stackCloudOverride: string = VerticalToolsUrl.getOverrideString("cloud", cloud + "_stack");
        const verticalOverride: string = VerticalToolsUrl.getOverrideString("vertical", vertical);
        const azureVerticalOverride: string = VerticalToolsUrl.getOverrideString("vertical", vertical + "_azure");
        const stackVerticalOverride: string = VerticalToolsUrl.getOverrideString("vertical", vertical + "_stack");
        const commitOverride: string = VerticalToolsUrl.getOverrideString("commit", commit);
        const cloudOverride: string = VerticalToolsUrl.getOverrideString("cloud", cloud);
        const applicationOverride: string = VerticalToolsUrl.getOverrideString("application", application);

        // Playstream overrides        
        const processorOverride: string = VerticalToolsUrl.getOverrideString("processor", "");
        const shardOverride: string = VerticalToolsUrl.getOverrideString("shard", "");
        const streamOverride: string = VerticalToolsUrl.getOverrideString("stream", "");

        // Playstream Azure overrides
        const playstreamProcessorOverride: string = VerticalToolsUrl.getOverrideString("processor", VerticalToolsUrl.getPlayStreamProcessorName(application));
        const playstreamApplicationOverride: string = VerticalToolsUrl.getOverrideString("application", VerticalToolsUrl.getPlayStreamApplicationName(application));
        const playstreamAzureBaseOverrides = `${azureCloudOverride},${playstreamProcessorOverride},${playstreamApplicationOverride}`;
        const playstreamStackBaseOverrides = `${stackCloudOverride},${playstreamProcessorOverride},${playstreamApplicationOverride}`;

        const baseOverrides = `${cloudOverride},${verticalOverride},${commitOverride},${applicationOverride}`;
        const additionalPlayStreamOverrides = `${streamOverride},${shardOverride},${processorOverride}`;

        const cloudVerticalCommitApplicationOverrides = `${overridePrefix}${baseOverrides}${overrideSuffix}`;
        const playStreamOverrides = `${overridePrefix}${baseOverrides},${additionalPlayStreamOverrides}${overrideSuffix}`;
        const playStreamAzureOverrides = `${overridePrefix}${playstreamAzureBaseOverrides}${overrideSuffix}`;
        const playStreamStackOverrides = `${overridePrefix}${playstreamStackBaseOverrides}${overrideSuffix}`;
        const cloudVerticalCommitOverrides = `${overridePrefix}${cloudOverride},${verticalOverride},${commitOverride}${overrideSuffix}`;

        const azureOverrides = `${overridePrefix}${azureCloudOverride},${azureVerticalOverride},${applicationOverride},${commitOverride}${overrideSuffix}`;
        const stackOverrides = `${overridePrefix}${stackCloudOverride},${stackVerticalOverride},${applicationOverride},${commitOverride}${overrideSuffix}`;
        const stackVerticalMainserverOverrides = `${overridePrefix}${stackCloudOverride},${stackVerticalOverride},${VerticalToolsUrl.getOverrideString("application", "mainserver_stack")},${commitOverride}${overrideSuffix}`;

        switch (application) {
            case "gamemanager":
            case "gameserverwrangler":
            case "logicserver":
            case "metricsprocessor":
            case "playerportal":
            case "queueprocessor":
            case "reportserver":
            case "taskprocessor":
            case "azureresourceprovider":
                return VerticalToolsUrl.getDashboardUrl(application, cloud, cloudVerticalCommitOverrides);
            case "mainserver_azure":
                return VerticalToolsUrl.getDashboardUrl("mainserver", cloud, azureOverrides);
            case "mainserver_stack":
            case "gamemanager_stack":
            case "insightsproxy_stack":
            case "logicserver_stack":
            case "queueprocessor_stack":
            case "playerportal_stack":
            case "taskprocessor_stack":
                return VerticalToolsUrl.getDashboardUrl(VerticalToolsUrl.getPfApplicationName(application), cloud, stackOverrides);
            case "mainserver":
            case "mainserverleaderboard":
            case "mainservercloudscriptint":
            case "mainserverplaystreamint":
            case "mainserverinsightsproxy":
            case "mainservermatchproxy":
                return VerticalToolsUrl.getDashboardUrl("mainserver", cloud, cloudVerticalCommitApplicationOverrides);
            case "cloudscriptint_stack":
                return VerticalToolsUrl.getDashboardUrl("mainserver", cloud, stackOverrides);
            case "matchmakerprimary":
            case "matchmakersecondary":
                return VerticalToolsUrl.getDashboardUrl("matchmaker", cloud, cloudVerticalCommitApplicationOverrides);
            case "legacymatchmaker_stack":
                return VerticalToolsUrl.getDashboardUrl("matchmaker", cloud, stackOverrides);
            case "playstreamentityprofile":
                return VerticalToolsUrl.getDashboardUrl("playstream", cloud, playStreamOverrides);
            case "playstream_actionprocessor_azure":
            case "playstream_entityprofile_azure":
            case "playstream_entityaction_azure":
            case "playstream_headdispatcher_azure":
            case "playstream_index_azure":
            case "playstream_archiver_azure":
            case "playstream_debugger_azure":
            case "playstream_stats_azure":
            case "playstream_webhooks_azure":
                return VerticalToolsUrl.getDashboardUrl("playstream", cloud, playStreamAzureOverrides);
            case "playstream_headdispatcher_stack":
            case "playstream_actionprocessor_stack":
            case "playstream_analyticsprocessor_stack":
            case "playstream_entityprofileeventprocessor_stack":
            case "playstream_entityactionprocessor_stack":
            case "playstream_indexprocessor_stack":
            case "playstream_segmentprocessor_stack":
            case "playstream_statsprocessor_stack":
            case "playstream_webhooksprocessor_stack":
            case "playstream_eventarchiver_stack":
            case "playstream_livesite_stack":
            case "playstream_playstreammonitor_stack":
                return VerticalToolsUrl.getDashboardUrl("playstream", cloud, playStreamStackOverrides);
            case "playstreamlivesite":
                return VerticalToolsUrl.getDashboardUrl("playstream/playstreamlivesite", cloud, cloudVerticalCommitOverrides);
            case "playstreammonitor":
                return VerticalToolsUrl.getDashboardUrl("playstream/playstreammonitor", cloud, cloudVerticalCommitOverrides);
            default:
                return "about:blank";
        }
    }

    private static getKustoConnection(cloud: string) {
        if (VerticalToolsUrl.isProductionCloud(cloud)) {
            return "https%3a%2f%2fgdeint.kusto.windows.net/databases/32d28fcb84664ea0841f9bd259f375c7";
        }
        return "https%3a%2f%2fgdeint.kusto.windows.net/databases/PlayFabAnalyticsLogsTest";
    }

    private static getTenant(cloud: string, application: string) {
        return this.isStackApplication(application) ?
            VerticalToolsUrl.isProductionCloud(cloud) ? "AzurePlayFabProd" : "AzurePlayFabDev" :
            VerticalToolsUrl.isProductionCloud(cloud) ? "PlayFabMainServerProd" : "PlayFabMainServerDev";
    }

    public static kustoLogsForApplicationCommit(vertical: string, application: string, commit: string, cloud: string): string {
        const tenant = this.getTenant(cloud, application);
        var applicationQueryArr: Array<string> = [
            'Log',
            `| where Tenant == "${tenant}"`,
            `| where TIMESTAMP >= ago(6h)`,
            `| where pf_cloud == "${cloud}"`,
            `| where pf_vertical == "${vertical}"`,
            `| where pf_application == "${VerticalToolsUrl.getPfApplicationName(application)}"`,
            `| where severityNumber >= 13`];
        var traefikQueryArr: Array<string> = [
            'Traefik',
            `| where Tenant == "${tenant}"`,
            `| where TIMESTAMP >= ago(6h)`,
            `| where cloud == "${cloud}"`,
            `| where vertical == "${vertical}"`,
            `| where app == "${VerticalToolsUrl.getPfApplicationName(application)}"`,
            `| limit 50`];
        var queryArr: Array<string> = application === "traefik_stack" ? traefikQueryArr : applicationQueryArr;

        const kustoLogsUrl = "https://dataexplorer.azure.com/clusters/" +
            `${VerticalToolsUrl.getKustoConnection(cloud)}?query=${encodeURIComponent(queryArr.join('\n'))}`;

        return kustoLogsUrl;
    }

    public static kustoLogsDgrepServerQuery(tenant: string, vertical: string, application: string, cloud: string): string {
        const stackAwareVertical: string = VerticalToolsUrl.isStackApplication(application)
            ? VerticalToolsUrl.getStampName(cloud)
            : vertical;
        var applicationQueryArr: Array<string> = [
            'source',
            `| where Tenant == "${tenant}"`,
            `| where toint(severityNumber) >= 13`,
            `| where pf_cloud == "${cloud}"`,
            `| where pf_vertical == "${vertical}"`,
            `| where pf_application == "${VerticalToolsUrl.getPfApplicationName(application)}"`];
        var traefikQueryArr: Array<string> = [
            'source',
            `| where Tenant == "${tenant}"`,
            `| where cloud == "${cloud}"`,
            `| where vertical == "${stackAwareVertical}"`,
            `| where app == "${VerticalToolsUrl.getPfApplicationName(application)}"`];
        var queryArr: Array<string> = application === "traefik_stack" ? traefikQueryArr : applicationQueryArr;

        return encodeURIComponent(queryArr.join('\n'));
    }

    public static kustoLogsDgrepClientQuery(): string {
        var queryArr: Array<string> = [
            'source',
            '| sort by PreciseTimeStamp desc'];

        return encodeURIComponent(queryArr.join('\n'));
    }

    public static mdmLogsForApplicationCommit(vertical: string, application: string, commit: string, cloud: string): string {

        const queryString = "?page=logs&be=DGrep&offset=-60&offsetUnit=Minutes&UTC=false" +
            `&ep=${VerticalToolsUrl.getLogEndpoint(cloud)}` +
            `&ns=${VerticalToolsUrl.getLogNamespace(cloud, application)}` +
            `&en=${VerticalToolsUrl.getLogEventNames(cloud, application)}` +
            "&scopingConditions=[" +
            `["cloud","${cloud}"],` +
            `["vertical","${vertical}"],` +
            `["application","${application}"],` +
            `["commit","${commit}"]]` +
            "&conditions=[]" +
            `&clientQuery=${VerticalToolsUrl.getLogOrderByQuery(cloud)}` +
            "&chartsVisible=true" +
            "&chartEditorVisible=false" +
            "&chartType=Column" +
            `&chartLayers=[["","groupby%20TIMESTAMP.roundDown(\\"PT1M\\")%20as%20X%20let%20Count%20%3D%20Count()"]]`;

        const queryAzureLogString = "?page=logs&be=DGrep&offset=-15&offsetUnit=Minutes&UTC=false" +
            `&ep=${VerticalToolsUrl.getLogEndpoint(cloud)}` +
            `&ns=${VerticalToolsUrl.getLogNamespace(cloud, application)}` +
            "&en=Log" +
            `&serverQuery=${VerticalToolsUrl.kustoLogsDgrepServerQuery(
                VerticalToolsUrl.isProductionCloud(cloud) ? "PlayFabMainServerProd" : "PlayFabMainServerDev", vertical, application, cloud)}` +
            "&serverQueryType=kql" +
            `&kqlClientQuery=${VerticalToolsUrl.kustoLogsDgrepClientQuery()}` +
            "&aggregatesVisible=true" +
            "&aggregates=[%22Count%20by%20pf_commit%22,%22Count%20by%20pf_application%22,%22Count%20by%20k8s_cluster%22,%22Count%20by%20severityText%22,%22Count%20by%20name%22,%22Count%20by%20pf_vertical%22]" +
            "&chartsVisible=false" +
            "&chartEditorVisible=false" +
            "&chartType=Column" +
            `&chartLayers=[["","groupby%20TIMESTAMP.roundDown(\\"PT1M\\")%20as%20X%20let%20Count%20%3D%20Count()"]]`;

        const queryStackLogString = "?page=logs&be=DGrep&offset=-15&offsetUnit=Minutes&UTC=false" +
            `&ep=${VerticalToolsUrl.getLogEndpoint(cloud)}` +
            `&ns=${VerticalToolsUrl.getLogNamespace(cloud, application)}` +
            "&en=Log" +
            `&serverQuery=${VerticalToolsUrl.kustoLogsDgrepServerQuery(
                VerticalToolsUrl.isProductionCloud(cloud) ? "AzurePlayFabProd" : "AzurePlayFabDev", vertical, application, cloud)}` +
            "&serverQueryType=kql" +
            `&kqlClientQuery=${VerticalToolsUrl.kustoLogsDgrepClientQuery()}` +
            "&aggregatesVisible=true" +
            "&aggregates=[%22Count%20by%20pf_commit%22,%22Count%20by%20pf_application%22,%22Count%20by%20k8s_cluster%22,%22Count%20by%20severityText%22,%22Count%20by%20name%22,%22Count%20by%20pf_vertical%22]" +
            "&chartsVisible=false" +
            "&chartEditorVisible=false" +
            "&chartType=Column" +
            `&chartLayers=[["","groupby%20TIMESTAMP.roundDown(\\"PT1M\\")%20as%20X%20let%20Count%20%3D%20Count()"]]`;

        const queryPlayStreamAzureString = "?page=logs&be=DGrep&offset=-60&offsetUnit=Minutes&UTC=false" +
            `&ep=${VerticalToolsUrl.getLogEndpoint(cloud)}` +
            `&ns=${VerticalToolsUrl.getLogNamespace(cloud, application)}` +
            `&en=${VerticalToolsUrl.getLogEventNames(cloud, application)}` +
            "&scopingConditions=[" +
            `["Tenant","${VerticalToolsUrl.isProductionCloud(cloud) ? "PlayFabPlaystreamProd" : "PlayFabPlaystreamDev"}"]]` +
            "&conditions=[" +
            `["cloud","%3D%3D","${cloud}"],` +
            `["container_name","%3D%3D","${VerticalToolsUrl.getPlayStreamContainerName(application)}"]]` +
            `&clientQuery=${VerticalToolsUrl.getLogOrderByQuery(cloud)}`;

        switch (application) {
            case "gamemanager":
            case "gameserverwrangler":
            case "logicserver":
            case "mainserver":
            case "mainservercloudscriptint":
            case "mainserverleaderboard":
            case "mainserverplaystreamint":
            case "mainserverinsightsproxy":
            case "mainservermatchproxy":
            case "matchmakerprimary":
            case "matchmakersecondary":
            case "metricsprocessor":
            case "playerportal":
            case "playstreamentityprofile":
            case "playstreamlivesite":
            case "playstreammonitor":
            case "queueprocessor":
            case "reportserver":
            case "taskprocessor":
            case "azureresourceprovider":
                return `https://portal.microsoftgeneva.com/logs/dgrep${queryString}`;
            case "playstream_actionprocessor_azure":
            case "playstream_entityprofile_azure":
            case "playstream_entityaction_azure":
            case "playstream_headdispatcher_azure":
            case "playstream_index_azure":
            case "playstream_archiver_azure":
            case "playstream_debugger_azure":
            case "playstream_stats_azure":
            case "playstream_webhooks_azure":
                return `https://portal.microsoftgeneva.com/logs/dgrep${queryPlayStreamAzureString}`;
            case "mainserver_azure":
                return `https://portal.microsoftgeneva.com/logs/dgrep${queryAzureLogString}`;
            case "mainserver_stack":
            case "queueprocessor_stack":
            case "taskprocessor_stack":
            case "legacymatchmaker_stack":
            case "playerportal_stack":
            case "gamemanager_stack":
            case "logicserver_stack":
            case "cloudscriptint_stack":
            case "insightsproxy_stack":
                return `https://portal.microsoftgeneva.com/logs/dgrep${queryStackLogString}`;

            default:
                return "about:blank";
        }
    }

    public static mdmDashClusterExist(application: string) {
        return this.isStackApplication(application);
    }

    public static mdmDashExist(application: string) {
        switch (application) {
            case "gamemanager":
            case "gamemanager_stack":
            case "gameserverwrangler":
            case "logicserver":
            case "logicserver_stack":
            case "mainserver":
            case "mainserver_azure":
            case "mainserver_stack":
            case "mainservercloudscriptint":
            case "cloudscriptint_stack":
            case "mainserverplaystreamint":
            case "mainserverleaderboard":
            case "mainserverinsightsproxy":
            case "insightsproxy_stack":
            case "mainservermatchproxy":
            case "matchmakerprimary":
            case "matchmakersecondary":
            case "legacymatchmaker_stack":
            case "metricsprocessor":
            case "playerportal":
            case "playerportal_stack":
            case "playstreamentitydispatch":
            case "playstreamlivesite":
            case "playstreammonitor":
            case "playstream_actionprocessor_azure":
            case "playstream_entityprofile_azure":
            case "playstream_entityaction_azure":
            case "playstream_headdispatcher_azure":
            case "playstream_index_azure":
            case "playstream_archiver_azure":
            case "playstream_debugger_azure":
            case "playstream_stats_azure":
            case "playstream_webhooks_azure":
            case "playstream_headdispatcher_stack":
            case "playstream_actionprocessor_stack":
            case "playstream_analyticsprocessor_stack":
            case "playstream_entityprofileeventprocessor_stack":
            case "playstream_entityactionprocessor_stack":
            case "playstream_indexprocessor_stack":
            case "playstream_segmentprocessor_stack":
            case "playstream_statsprocessor_stack":
            case "playstream_webhooksprocessor_stack":
            case "playstream_eventarchiver_stack":
            case "playstream_livesite_stack":
            case "playstream_playstreammonitor_stack":
            case "playstreamentityprofile":
            case "queueprocessor":
            case "queueprocessor_stack":
            case "reportserver":
            case "taskprocessor":
            case "taskprocessor_stack":
            case "azureresourceprovider":
                return true;
            default:
                return false;
        }
    }

    public static kustoLogsExist(application: string) {
        if (application == "mainserver_azure" || this.isStackApplication(application)) {
            return true;
        }
        return false;
    }

    public static mdmLogsExist(application: string) {
        switch (application) {
            case "gamemanager":
            case "gamemanager_stack":
            case "gameserverwrangler":
            case "logicserver":
            case "logicserver_stack":
            case "mainserver":
            case "mainservercloudscriptint":
            case "cloudscriptint_stack":
            case "mainserverplaystreamint":
            case "mainserver_azure":
            case "mainserver_stack":
            case "mainserverleaderboard":
            case "mainserverinsightsproxy":
            case "insightsproxy_stack":
            case "mainservermatchproxy":
            case "matchmakerprimary":
            case "matchmakersecondary":
            case "legacymatchmaker_stack":
            case "playerportal":
            case "playerportal_stack":
            case "playstreamentitydispatch":
            case "playstreamlivesite":
            case "playstreammonitor":
            case "playstream_actionprocessor_azure":
            case "playstream_entityprofile_azure":
            case "playstream_entityaction_azure":
            case "playstream_headdispatcher_azure":
            case "playstream_index_azure":
            case "playstream_archiver_azure":
            case "playstream_debugger_azure":
            case "playstream_stats_azure":
            case "playstream_webhooks_azure":
            case "playstreamentityprofile":
            case "queueprocessor":
            case "queueprocessor_stack":
            case "metricsprocessor":
            case "reportserver":
            case "taskprocessor":
            case "taskprocessor_stack":
            case "azureresourceprovider":
                return true;

            default:
                return false;
        }
    }

    public static verticalIntegrationTestForApplicationCommit(vertical: string, commit: string): string {
        return "https://pf-jenkins.playfab.com/view/Verticals/job/vertical-integration-test/parambuild/?commit="
            + commit
            + "&vertical="
            + vertical;
    }

    public static cloudFormationStack(region: string, stackId: string): string {
        return `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stack/detail?stackId=${encodeURIComponent(stackId)}`;
    }

    public static ec2Instances(region: string, stackId: string): string {
        return `https://${region}.console.aws.amazon.com/ec2/v2/home?region=${region}#Instances:tag:aws:cloudformation:stack-id=${stackId}`;
    }

    public static elb(region: string, elbName: string): string {
        return `https://${region}.console.aws.amazon.com/ec2/v2/home?region=${region}#LoadBalancers:search=${elbName}`;
    }

    public static asg(region: string, verticalName: string, appName: string, commit: string): string {
        return `https://${region}.console.aws.amazon.com/ec2/autoscaling/home?region=${region}#AutoScalingGroups:filter=${verticalName}-${appName}-${commit}`;
    }

    public static githubCommit(commit: string): string {
        return "https://github.com/PlayFab/pf-main/commit/" + commit;
    }

    private static getDashboardUrl(dashboardPath: string, cloud: string, overrides: string): string {
        return `https://jarvis-west.dc.ad.msft.net/dashboard/${VerticalToolsUrl.getMdmAccount(cloud)}/${dashboardPath}${overrides}`;
    }

    private static getLogEndpoint(cloud: string) {
        if (VerticalToolsUrl.isChinaCloud(cloud)) {
            return "CA Mooncake";
        }
        if (VerticalToolsUrl.isProductionCloud(cloud)) {
            return "Diagnostics PROD";
        }
        return "Test";
    }

    private static getLogEventNames(cloud: string, application: string) {
        if (VerticalToolsUrl.isAzureApplication(application) || VerticalToolsUrl.isAwsLinuxApplication(application)) {
            return "PlayFab";
        }

        return "DiagnosticEvent";
    }

    private static getLogNamespace(cloud: string, application: string) {
        if (VerticalToolsUrl.isAzureApplication(application)) {
            return "pfk8slogs";
        }

        if (VerticalToolsUrl.isAwsLinuxApplication(application)) {
            return "pflinuxvmlogs";
        }

        return "pflogs";
    }

    private static getLogOrderByQuery(cloud: string) {
        return "orderby PreciseTimeStamp desc";
    }

    private static getMdmAccount(cloud: string) {
        if (VerticalToolsUrl.isChinaCloud(cloud)) {
            return "PlayFabPlayerServicesCN";
        }
        if (VerticalToolsUrl.isProductionCloud(cloud)) {
            return "PlayFabPlayerServices";
        }
        return "PlayFabPlayerServicesTest";
    }

    private static getStackPartitionUrl(cloud: string) {
        if (VerticalToolsUrl.isProductionCloud(cloud)) {
            return "azureplayfab.com";
        }
        return "azureplayfabdev.com";
    }

    // Update as necessary
    private static getStampName(cloud: string) {
        switch (cloud) {
            case "data-svcs":
                return "datasvcs";
            case "devservices":
                return "dev";
            case "matchmaking":
                return "mm";
            case "multiplayer":
                return "mps";
            case "player-svcs":
                return "plr";
            default:
                return cloud;
        }
    }

    public static getCloudName(stamp: string) {
        switch (stamp) {
            case "datasvcs":
                return "data-svcs";
            case "dev":
                return "devservices";
            case "mm":
                return "matchmaking";
            case "mps":
                return "multiplayer";
            case "plr":
                return "player-svcs";
            default:
                return stamp;
        }
    }

    private static getOverrideString(id: string, replacement: string): string {
        return `{%22query%22:%22//*[id=%27${id}%27]%22,%22key%22:%22value%22,%22replacement%22:%22${replacement}%22}`;
    }

    private static isChinaCloud(cloud: string) {
        return (cloud === "china");
    }

    // this check matches IsProductionCloud check in ami-util.ps1 and VerticalService.cs
    private static isProductionCloud(cloud: string) {
        return (cloud === "main") || (cloud === "upr-usj") || (cloud === "upr-ush") || (cloud === "rblx");
    }

    private static isStackApplication(application: string) {
        return application.endsWith("_stack");
    }

    private static isAzureApplication(application: string) {
        return application.endsWith("_azure") || VerticalToolsUrl.isStackApplication(application);
    }

    private static isAwsLinuxApplication(application: string) {
        return !VerticalToolsUrl.isAzureApplication(application) && (application.includes("mainserver") || application.includes("matchmaker") || application === "logicserver" || application === "queueprocessor" || application === "gamemanager" || application === "playerportal");
    }

    private static getPlayStreamProcessorName(verticalApplicationName: string) {
        switch (verticalApplicationName) {
            case "playstream_actionprocessor_azure":
            case "playstream_actionprocessor_stack":
                return "actionprocessor_azure"
            case "playstream_analyticsprocessor_stack":
                return "analyticsprocessor_azure"
            case "playstream_segmentprocessor_stack":
                return "playstream_segment_azure"
            case "playstream_entityprofile_azure":
            case "playstream_entityprofileeventprocessor_stack":
                return "entity_profile_azure"
            case "playstream_entityaction_azure":
            case "playstream_entityactionprocessor_stack":
                return "entityactionprocessor_azure"
            case "playstream_headdispatcher_azure":
            case "playstream_headdispatcher_stack":
                return "head_dispatcher_azure";
            case "playstream_index_azure":
            case "playstream_indexprocessor_stack":
                return "playstreamindexprocessor_azure";
            case "playstream_archiver_azure":
            case "playstream_eventarchiver_stack":
                return "event_archiver_azure";
            case "playstream_debugger_azure":
                return "playstream_debugger_azure";
            case "playstream_stats_azure":
            case "playstream_statsprocessor_stack":
                return "playstreamstatsprocessor_azure"
            case "playstream_webhooks_azure":
            case "playstream_webhooksprocessor_stack":
                return "playstream_webhooks_azure";
            default:
                return "";
        }
    }

    private static getPlayStreamApplicationName(verticalApplicationName: string) {
        switch (verticalApplicationName) {
            case "playstream_headdispatcher_stack":
                return "headdispatcher_stack";
            case "playstream_actionprocessor_stack":
                return "actionprocessor_stack";
            case "playstream_analyticsprocessor_stack":
                return "analyticsprocessor_stack";
            case "playstream_entityprofileeventprocessor_stack":
                return "entityprofileeventprocessor_stack";
            case "playstream_entityactionprocessor_stack":
                return "entityactionprocessor_stack";
            case "playstream_indexprocessor_stack":
                return "indexprocessor_stack";
            case "playstream_segmentprocessor_stack":
                return "segmentprocessor_stack";
            case "playstream_statsprocessor_stack":
                return "statsprocessor_stack";
            case "playstream_webhooksprocessor_stack":
                return "webhooksprocessor_stack";
            case "playstream_eventarchiver_stack":
                return "eventarchiver_stack";
            case "playstream_livesite_stack":
                return "livesite_stack";
            case "playstream_playstreammonitor_stack":
                return "playstreammonitor_stack";
            case "playstream_actionprocessor_azure":
                return "actionprocessor_azure"
            case "playstream_entityprofile_azure":
                return "entity_profile_azure"
            case "playstream_entityaction_azure":
                return "entityactionprocessor_azure"
            case "playstream_headdispatcher_azure":
                return "headdispatcher_azure";
            case "playstream_index_azure":
                return "playstreamindexprocessor_azure";
            case "playstream_archiver_azure":
                return "eventarchiver_azure";
            case "playstream_debugger_azure":
                return "playstreamlivesite_azure";
            case "playstream_stats_azure":
                return "playstreamstatsprocessor_azure"
            case "playstream_webhooks_azure":
                return "playstreamwebhooks_azure";
            default:
                return "";
        }
    }

    private static getPlayStreamContainerName(verticalApplicationName: string) {
        switch (verticalApplicationName) {
            case "playstream_actionprocessor_azure":
                return "actionprocessor"
            case "playstream_entityprofile_azure":
                return "entityprofile"
            case "playstream_entityaction_azure":
                return "entityactionprocessor"
            case "playstream_headdispatcher_azure":
                return "headdispatcher";
            case "playstream_index_azure":
                return "indexprocessor";
            case "playstream_archiver_azure":
                return "eventarchiver";
            case "playstream_debugger_azure":
                return "livesite";
            case "playstream_stats_azure":
                return "statsprocessor"
            case "playstream_webhooks_azure":
                return "webhooks";
            default:
                return "";
        }
    }
}

export class VerticalToolsSort {
    public static sortStamps = (a: string, b: string) => {
        // main is always at the top
        if (a === "main") {
            return -1;
        }

        if (b === "main") {
            return 1;
        }

        // all other stamps are sorted alphabetically
        if (a > b) {
            return 1;
        }

        if (a < b) {
            return -1;
        }

        return 0;
    };

    public static sortClouds = (a: ICloud, b: ICloud) => {
        if (a.cloud === "main") {
            return -1;
        }

        if (b.cloud === "main") {
            return 1;
        }

        if (a.cloud < b.cloud) {
            return -1;
        }

        if (a.cloud > b.cloud) {
            return 1;
        }

        return 0;
    };

    public static sortVerticals = (a: IVertical, b: IVertical) => {
        // master is always at the top
        if (a.vertical === "master") {
            return -1;
        }

        if (b.vertical === "master") {
            return 1;
        }

        // low is always second from the top
        if (a.vertical === "low") {
            return -1;
        }

        if (b.vertical === "low") {
            return 1;
        }

        if (a.vertical.startsWith("p-") && !b.vertical.startsWith("p-")) {
            return -1;
        }

        if (b.vertical.startsWith("p-") && !a.vertical.startsWith("p-")) {
            return 1;
        }

        // all other verticals are sorted alphabetically
        if (a.vertical > b.vertical) {
            return 1;
        }

        if (a.vertical < b.vertical) {
            return -1;
        }

        return 0;
    };

    public static sortReplicaSet = (a: IReplicaSet, b: IReplicaSet) => {
        return moment.utc(a.ReplicaSetCreationTime) > moment.utc(b.ReplicaSetCreationTime) ? 1 : -1;
    }

    public static sortApps = (a: string, b: string) => {
        const appSortPriorityList = [
            "mainserver",
            "mainserver_azure",
            "traefik",
            "mainserverleaderboard",
            "mainserverplaystreamint",
            "mainservermatchproxy",
            "logicserver",
            "cloudscriptint",
            "mainservercloudscriptint",
            "gamemanager",
            "playstream_headdispatcher_azure",
            "playstream_headdispatcher",
            "playstream_actionprocessor_azure",
            "playstream_actionprocessor",
            "playstream_analyticsprocessor",
            "playstream_archiver_azure",
            "playstream_debugger_azure",
            "playstream_entityprofile_azure",
            "playstream_entityprofileeventprocessor",
            "playstream_entityaction_azure",
            "playstream_entityactionprocessor",
            "playstream_eventarchiver",
            "playstream_index_azure",
            "playstream_indexprocessor",
            "playstream_livesite",
            "playstream_playstreammonitor",
            "playstream_segmentprocessor",
            "playstream_stats_azure",
            "playstream_statsprocessor",
            "playstream_webhooks_azure",
            "playstream_webhooksprocessor",
            "playerportal",
            "legacymatchmaker",
            "matchmakerprimary",
            "matchmakersecondary",
            "gameserverwrangler",
            "queueprocessor",
            "metricsprocessor",
            "taskprocessor",
        ];
        // Sort by priority list
        for (let i = 0; i <= appSortPriorityList.length; i++) {
            if (a === appSortPriorityList[i]) {
                return -1;
            } else if (b === appSortPriorityList[i]) {
                return 1;
            }
        }

        // Sort alphabetically for remainder
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        }
        return 0;
    };
}